.toast {
    position: fixed;
    bottom: 50px;
    left: 50%;
    padding: 15px;
    background-color: rgba(0,0,0,.7);
    color: #fff;
    transform: translate(-50%, 0);
    animation: fadein 0.5s;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    &.error {
      background-color: rgba(234,38,23,.7)
    }
    &.success {
      background-color: rgba(76,175,80,.8)
    }
  }
  @keyframes fadein {
      from {bottom: 0; opacity: 0;} 
      to {bottom: 50px; opacity: 1;}
  }